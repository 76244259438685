import React from 'react'
import './styles.css'
// images 
import Box from '../assets/section-yt/box.svg'
import Cart from '../assets/section-yt/cart.svg'
import Client from '../assets/section-yt/clients.svg'
import Doc from '../assets/section-yt/doc.svg'
import Orders from '../assets/section-yt/orders.svg'
import Porducts from '../assets/section-yt/Products.svg'
import Reports from '../assets/section-yt/reports.svg'
import Percent from '../assets/section-yt/percent.svg'
import Settings from '../assets/section-yt/settings.svg'
import Ellipse from '../assets/section-yt/ellipse.svg'

export default function SectinoYt() {

    const images1 = [
        {
            image: Box
        },
        {
            image: Cart
        },
        {
            image: Client
        },
        {
            image: Doc
        },
        {
            image: Orders
        }
    ]

    const images2 = [
        {
            image: Percent
        },
        {
            image: Porducts
        },
        {
            image: Reports
        },
        {
            image: Settings
        },
    ]

    const renderBox = (image) => {
        return (
            <div className="box-coontainer">
                <img src={image} alt="" className="box-image" />
            </div>
        )
    }

    const gotoyoutube = () => {
        window.open('https://youtube.com/playlist?list=PLs_wYR3S41Bxda5QKAVNWt78NroVi5oqL', '_blank')
    }

  return (
    <div className='section-yt-container'  id='yt'>
        <img src={Ellipse} alt="" className='yt-ellipse' />
        <p className='heading-large blue-100 m-0 w-90 text-center'>¿Cómo empezar?</p>
        <p className="text-size-large neutral-80 mb-m w-90 text-center">Visita nuestro canal de Youtube y aprende sobre todas las funcionalidades que Posmit tiene para ti.</p>
        <button className="btn-primary" onClick={gotoyoutube}>Ir a Youtube</button>

        <div className="elements-container">
            <div className='elements'>
                {
                    images1.map(el => {
                        return renderBox(el.image)
                    })
                }
            </div>
            <div className='elements'>
                {
                    images2.map(el => {
                        return renderBox(el.image)
                    })
                }
            </div>
        </div>
    </div>
  )
}
