import React from 'react'
import './styles.css'

import Ppl from '../assets/cta2/ppl.png'
import Image from '../assets/cta2/image.jpg'
import Mail from '../assets/footer/mail.svg'
import Wa from '../assets/footer/whatsapp.svg'
import Arrow from '../assets/arrow.svg'

import { config } from '../constants'

const CallToAction2 = () => {

    const whatsapp = () => {
        window.open(`https://wa.me/${config.wsNumber}`);
    }

    return(
        <div className='cta2-container' id='cta2'>
            <div className='section-two-title-container'>
                <h2 className='heading-medium blue-100 text-center w-m m-0-auto'>¡Vende mejor, hoy!</h2>
                <p className='text-size-large neutral-80 text-center w-m m-0-auto mt-m'>Ingresa a Posmit y digitaliza las finanzas de tu negocio</p>
            </div>
            {/* <div className='m-0-auto mb-l'>
                <div className='btn-primary btn-large' style={{ width: 350, margin: '20px auto' }}>Descarga la app</div>
            </div> */}
            <div className='cta2-data-container flex ai-center'>
                <div className='cta2-box w-xl'>
                    <p className='heading-small blue-100 w-xl'>Para adquirir Posmit o solicitar información</p>
                    <p className='text-size-small neutral-80 w-xl'>Escríbenos a nuestro correo electrónico, o comunícate con nosotros vía WhatsApp .</p>
                    <div className='flex ai-center w-100 pointer' style={{ height: 60 }}>
                        <img src={Mail} alt="mail" className='mr-s' />
                        <p className='heading-xsmall blue-100'>Info@posmit.app</p>
                        <img src={Arrow} alt="mail" className='ml-auto arrow' style={{ marginRight: 30, width: 32 }} />
                    </div>
                    <div className='divider' />
                    <div className='flex ai-center w-100 pointer' style={{ height: 60 }} onClick={whatsapp}>
                        <img src={Wa} alt="whatsapp" className='mr-s' />
                        <a href={`https://wa.me/${config.wsNumber}`} className='heading-xsmall blue-100 dec-none'>+58 412-535-4531</a>
                        <img src={Arrow} alt="mail" className='ml-auto arrow' style={{ marginRight: 30, width: 32 }} />
                    </div>
                </div>
                <div className='cta2-box'>
                    <img src={Image} className='cta2-image' alt="cta2" />
                    <img src={Ppl} className='cta2-image-ppl' alt="cta2" />                    
                </div>
            </div>
        </div>
    )
}

export default CallToAction2