import React from 'react';
import './styles.css';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import data from './policies-personal-data.json'

const PoliciesPersonalData = () => {
    console.log("🚀 ~ file: TermAndConditions.jsx:6 ~ data:", data)

  const styles = {
    // bgColor: 'white',
    rowTitleColor: '#053F66',
    rowTitleTextSize: '24px',
    rowContentColor: '#70808D',
    owContentTextSize: '20px',
    rowContentPaddingLeft: 5,
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const renderTextWithBold = (text) => {
    return text.split('*').map((part, index) => {
      return index % 2 === 0 ? part : <span className='bold blue-100' key={index}>{part}</span>;
    });
  };


  return (
    <>
      <Header />
        <div className="container">
        
        <div>
            <h1 className='blue-100 text-center'>{data.title}</h1>
            <p className='blue-100 text-center'>{renderTextWithBold(data.subtitle)}</p>
            <h3 className='blue-100 text-center'>{data.label}</h3>


            {/* lista normal */}
            <div>
                <h2 className='blue-100'>{data.list.title}</h2>
                {data.list.map((item, index) => (
                    <>
                        <p className='neutral-80'>{renderTextWithBold(item.label)}</p>
                        <ol>
                        {
                            item.items.map(itemList => (
                                <>
                                    <li key={index}><p className='neutral-80'>{renderTextWithBold(itemList.label)}</p></li>
                                    {
                                        itemList?.sublist &&
                                        itemList?.sublist.map(itemSubList => (
                                            <div className='flex align-start ml-l'>
                                                <span className='mr-s'>{itemSubList.number}</span>
                                                <p key={index} className='neutral-80 mt-0'>{renderTextWithBold(item.label)}</p>
                                            </div>
                                        ))
                                    }
                                </>

                            ))
                        }
                        </ol>
                    </>
                ))}
            </div>

            {/* lista enumerada  */}
            <div>
                <ol>
                {data.numeric_list.map((list, index) => (
                        <li>
                            <p className='blue-100 bold'>{list.title}</p>
                            {list?.label && <p className='blue-100'>{renderTextWithBold(list.label)}</p>}
                                {
                                    list.list.map((item, index) => (
                                        <>
                                            <div className='flex align-start ml-l'>
                                                <span className='mr-s'>{item.label_number}</span>
                                                <p key={index} className='neutral-80 mt-0'>{renderTextWithBold(item.label)}</p>
                                            </div>
                                            {
                                                item?.items &&
                                                item.items.map(itemList => (
                                                    <div className='flex align-start ml-l'>
                                                        <span className='mr-s'>{itemList.number}</span>
                                                        <p key={index} className='neutral-80 mt-0'>{renderTextWithBold(itemList.content)}</p>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    ))
                                }
                        </li>
                ))}
                </ol>
            </div>

            {/* lista enumerada 2*/}
            {/* <div>
                {data.numeric_list_2.map((list, index) => (
                    <div key={index}>
                        <h2 className='blue-100'>{list.title}</h2>
                            {
                                list.list.map((item, index) => (
                                    <>
                                        <div className='flex align-start ml-l bold'>
                                            <span className='mr-s'>{item.label_number}</span>
                                            <p key={index} className='blue-100 mt-0 mb-0'>{item.label}</p>
                                        </div>
                                        {
                                            item.items.map(itemList => (
                                                <div className='flex align-start ml-l'>
                                                    <span className='mr-s'>{itemList.number}</span>
                                                    <p key={index} className='neutral-80 mt-0'>{itemList.content}</p>
                                                </div>
                                            ))
                                        }
                                    </>
                                ))
                            }
                    </div>
                ))}
            </div> */}

        </div>

        </div>
        <Footer />
    </>
  );
};

export default PoliciesPersonalData;
