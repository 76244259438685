import React, { useState } from 'react'
import './styles.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Check from '../assets/section-5/check.svg'

const AllThatYouNeed = () => {

    const [value, setValue] = useState('Monedas');
    const data = [
        {
            category: 'Monedas',
            data: [
                {
                    title: 'Listado de monedas',
                    description: 'Elige todas las monedas que deseas recibir en tu negocio.',
                },
                {
                    title: 'Moneda principal y secundaria',
                    description: 'Visualiza todos los precios en las dos monedas de tu preferencia.',
                },
                {
                    title: 'Tasas de cambio',
                    description: 'Actualiza tus precios automáticamente según la tasa del día.',
                },
            ]
        },
        {
            category: 'Órdenes',
            data: [
                {
                    title: 'Una o múltiples órdenes de venta en simultáneo',
                    description: 'Abre varias órdenes de venta al mismo tiempo. Ideal para gestión de comandas.',
                },
                {
                    title: 'Historial de órdenes procesadas',
                    description: 'Consulta los detalles de todas las ventas realizadas en tu negocio.',
                },
                {
                    title: 'Devoluciones',
                    description: 'Recibe y reporta devoluciones de mercancía.',
                },
                {
                    title: 'Ventas rápidas',
                    description: 'Crea plantillas de órdenes con productos pre-cargados y agiliza tus cobros.',
                },
                {
                    title: 'Múltiples cajas y sucursales',
                    description: 'Visualiza las ventas realizadas por múltiples dispositivos de forma centralizada.',
                },
                {
                    title: 'Recibos Impresos',
                    description: 'Puedes imprimir en tu dispositivo Sunmi o en cualquier impresora inalámbrica.',
                },
                {
                    title: 'Mesas y comandas',
                    description: 'Gestiona tus comandas tanto de forma impresa como digital.',
                },
            ]
        },
        {
            category: 'Métodos de pago',
            data: [
                {
                    title: 'Pagos Mixtos',
                    description: 'Acepta distintos métodos de pago y monedas en una misma orden.',
                },
                {
                    title: 'Cierre de caja por moneda',
                    description: 'Lleva control de los montos recibidos en distintas monedas.',
                },
                {
                    title: 'Reportes sobre los métodos de pago más utilizados',
                    description: 'Posmit te informa cual es el volúmen de transacciones realizadas en los distintos métodos de pago',
                },
                {
                    title: 'Crea tus propios métodos de pago',
                    description: 'Crea métodos de pago personalizados en cualquier moneda para adaptarte a las necesidades de tus clientes.',
                },
                {
                    title: 'Resumen de tus pagos mixtos en los recibos',
                    description: 'Los recibos de venta registran la información de los pagos recibidos en varias monedas.',
                },
            ]
        },
        {
            category: 'Inventario',
            data: [
                {
                    title: 'Inventario de productos',
                    description: 'Crea productos indicando sus costos, unidades, impuestos y decide qué parámetros controlan la entrada y salida de mercancía',
                },
                {
                    title: 'Carga masiva de inventario',
                    description: 'Utiliza plantillas de hojas de cálculo para cargar múltiples productos a tu inventario en un solo movimiento.',
                },
                {
                    title: 'Alertas de reposición',
                    description: 'Posmit te mantiene al tanto de las cantidades bajas de productos en tu inventario.',
                },
                {
                    title: 'Entrada y salida de mercancía',
                    description: 'Posmit te permite registrar compras de mercancía para reposición, hacer reconteos, o registrar pérdidas en el inventario.',
                },
                {
                    title: 'Productos con consumibles',
                    description: 'Crea productos que contienen consumibles, para casos que requieran fabricación o para productos con ingredientes modificables.',
                },
                {
                    title: 'Impuestos a tus productos',
                    description: 'Elige los impuestos que aplican a tus productos y Posmit tomará en cuenta cualquier especificicación al definir los precios totales de venta.',
                },
                {
                    title: 'Código de barras y QR',
                    description: 'Al contar con tu dispositivo SUNMI, utiliza la cámara para escanear distintos tipos de códigos.',
                },
            ]
        },
        {
            category: 'Personal',
            data: [
                {
                    title: 'Control de permisos',
                    description: 'Crea roles para los empleados de tu empresa y decide qué acciones puede realizar cada uno dentro de la app.',
                },
                {
                    title: 'Ventas por empleado',
                    description: 'Guarda y consulta las ventas realizadas por cada empleado.',
                },
                {
                    title: 'Rendimiento por sucursal o empleado',
                    description: 'Compara los volúmenes de venta entre sucursales o entre empleados.',
                },
            ]
        },
        {
            category: 'Reportes',
            data: [
                {
                    title: 'Reportes de venta',
                    description: 'Visualiza un informe detallado para la analítica de tus operaciones.',
                },
                {
                    title: 'Exportar reportes',
                    description: 'Exporta los datos de tus reportes en formato PDF y hojas de cálculo',
                },
                {
                    title: 'Búsqueda en reportes',
                    description: 'Realiza búsquedas y filtra datos dentro de tus reportes de venta.',
                },
            ]
        },
        {
            category: 'Descuentos',
            data: [
                {
                    title: 'Crea descuentos personalizados',
                    description: 'Crea descuentos aplicables a un grupo de productos, o a todas tus ventas.',
                },
                {
                    title: 'Promociones programadas',
                    description: 'Asigna fechas de inicio y culminación a tus descuentos para crear promociones.',
                },
                {
                    title: 'Historial de ventas con descuento',
                    description: 'Consulta los resultados obtenidos de tus ventas con descuento.',
                },
            ]
        },
    ]
    const [info, setInfo] = useState(data[0]);

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
        const info = data[newValue]
        setInfo(info)
    };

    const renderInfo = (el) => {
        return(
            <div className='ayn-info-container'>
                <img src={Check} alt="check" />
                <div>
                    <p className='m-0 heading-xsmall '>{el.title}</p>
                    <p className='text-size-small neutral-80'>{el.description}</p>
                </div>
            </div>
        )
    }


    return (
        <div className='ayn-section-container mt-l'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    style={{ justifyContent: 'space-evenly', fontSize: 20 }}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="secondary tabs example"
                >
                    {
                        data.map((el, i) => {
                            return(
                                <Tab value={i} label={el.category} style={{ fontWeight: 'bold', fontSize: 20, fontFamily: 'Inter', textTransform: 'none', paddingLeft: 20, paddingRight: 25 }} />
                                )
                            })
                    }
                </Tabs>
            </Box>
            <p className='blue-80 heading-small mt-l'>{info.category}</p>
            <div className='ayn-info'>
                {
                    info.data.map(el => {
                        return renderInfo(el)
                    })
                }
            </div>
        </div>
    )
}

export default AllThatYouNeed