import React from 'react'
import './styles.css'

import Image1 from '../assets/section-3/image1.svg'
import Image2 from '../assets/section-3/image2.jpg'
import Image3 from '../assets/section-3/image3.jpg'
import Image4 from '../assets/section-3/image4.jpg'
import Image5 from '../assets/section-3/image5.jpg'

const SectionThree = () => {

    const data = [
        {
            image: Image1,
            title: 'Tus precios en múltiples monedas',
            subtitle: 'Simplificamos la gestión de tasas de cambio',
            span1: 'Tasas de cambio. ',
            text1: 'Actualiza todos tus precios automáticamente con la tasa del día y consulta el historial cuando lo necesites.',
            span2: 'Cierre de caja por moneda. ',
            text2: 'Lleva control de la entrada y salida de todas tus monedas.',
            left: true
        },
        {
            image: Image2,
            title: 'Varios dispositivos conectados',
            subtitle: 'Consulta la data de múltiples cajas o sucursales en tiempo real',
            span1: 'Ventas en simultáneo. ',
            text1: 'Vende con varios dispositivos conectados al mismo sistema de inventario.',
            span2: 'Observa todo en un solo lugar.',
            text2: ' Consulta los totales de las ventas de varias sucursales o dispositivos en una sola pantalla.',
            left: false
        },
        {
            image: Image3,
            title: 'Un inventario flexible',
            subtitle: 'Manejo de stock adaptable a la estructura de cualquier negocio',
            span1: 'Control de existencias, pérdidas y reposiciones. ',
            text1: 'Reporta y guarda un historial de las entradas y salidas de mercancía.',
            span2: 'Productos configurables a tu medida. ',
            text2: 'Crea distintos tipos de producto, ordénalos por categoría y utiliza cualquier unidad de medida para las cantidades.',
            left: true
        },
        {
            image: Image4,
            title: 'Tu apoyo contable',
            subtitle: 'Posmit te asiste con tus requerimientos fiscales',
            span1: 'Impuesto IGTF. ',
            text1: 'El cálculo del impuesto a transacciones en moneda extranjera se realiza automáticamente. ',
            span2: 'Lista de impuestos según tus necesidades. ',
            text2: 'Accede a una lista de impuestos actualizada según el marco legal vigente.',
            left: false
        },
        {
            image: Image5,
            title: 'Toma mejores decisiones',
            subtitle: 'Visualiza datos sobre el rendimiento de tu negocio',
            span1: 'Gráficos y reportes. ',
            text1: 'Observa los indicadores destacados de tus ventas y compara sus valores a lo largo del tiempo.',
            span2: 'Analiza múltiples compañías y sucursales. ',
            text2: 'Visualiza la data de tus ventas de forma individual o conjunta, para una o múltiples compañías y sucursales.',
            left: true
        },
    ]

    const renderData = (data) => {
        if(data.left && window.screen.width > 768){
            return(
                <div className='section-three-element-container'>
                    <div className='section-three-info-box'>
                        <h3 className='heading-medium blue-100'>{data.title}</h3>
                        <p className='text-size-large neutral-80'>{data.subtitle}</p>
                        <div className='divider' />
                        <p className='text-size-small neutral-80'><span className='blue-100 bold'>{data.span1}</span>{data.text1}</p>
                        <p className='text-size-small neutral-80'><span className='blue-100 bold'>{data.span2}</span>{data.text2}</p>
                    </div>
                    <div className='section-three-image-box'>
                        <img src={data.image} className='section-three-image' alt="imagen" />
                    </div>
                </div>
            )
        }
        return(
            <div className='section-three-element-container'>
                <div className='section-three-image-box'>
                    <img src={data.image} className='section-three-image' alt="imagen" />
                </div>
                <div className='section-three-info-box'>
                    <h3 className='heading-medium blue-100'>{data.title}</h3>
                    <p className='text-size-large neutral-80'>{data.subtitle}</p>
                    <div className='divider' />
                    <p className='text-size-small neutral-80'><span className='blue-100 bold'>{data.span1}</span>{data.text1}</p>
                    <p className='text-size-small neutral-80'><span className='blue-100 bold'>{data.span2}</span>{data.text2}</p>
                </div>
            </div>
        )
    }

    return(
        <div className='Section-three-container'>
            {
                data.map(info => {
                    return renderData(info)
                })
            }
        </div>
    )
}

export default SectionThree