import React from 'react';
import './styles.css';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import data from './Terms-conditions.json'
import { Link } from "react-router-dom";


const TermAndConditions = () => {
    console.log("🚀 ~ file: TermAndConditions.jsx:6 ~ data:", data)

  const styles = {
    // bgColor: 'white',
    rowTitleColor: '#053F66',
    rowTitleTextSize: '24px',
    rowContentColor: '#70808D',
    owContentTextSize: '20px',
    rowContentPaddingLeft: 5,
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

//   const renderTextWithBold = (text) => {
//     return text.split('*').map((part, index) => {
//       return index % 2 === 0 ? part : <span className='bold blue-100' key={index}>{part}</span>;
//     });
//   };

  const renderTextWithBold = (text) => {
    const linkText = "(Ingresar link de las Políticas de Protección de Datos Personales)";
    const linkUrl = "/terms-and-conditions/policies-personal-data";
  
    return text.split('*').map((part, index) => {
      if (part === linkText) {
        return <Link to={linkUrl} key={index}>Políticas de Protección de Datos Personales</Link>;
      } else {
        return index % 2 === 0 ? part : <span className='bold blue-100' key={index}>{part}</span>;
      }
    });
  };


  return (
    <>
      <Header />
        <div className="container">
        <p className="heading-large blue-100 m-0 text-center">Términos y condiciones</p>
        
        <div>
            <h1 className='blue-100'>{data.title}</h1>
            {data.paragraphs.map((paragraph, index) => (
                <p key={index} className='neutral-80'>
                {renderTextWithBold(paragraph)}
                </p>
            ))}

            {/* lista normal */}
            <div>
                <h2 className='blue-100'>{data.list.title}</h2>
                {data.list.items.map((item, index) => (
                    <ul>
                        <li key={index} className='neutral-80'>{renderTextWithBold(item)}</li>
                    </ul>
                ))}
            </div>

            {/* lista enumerada  */}
            <div>
                {data.numeric_list.map((list, index) => (
                    <div key={index}>
                        <h2 className='blue-100'>{list.title}</h2>
                            {
                                list.items.map((item, index) => (
                                    <>
                                        <div className='flex align-start ml-l'>
                                            <span className='mr-s'>{item.number}</span>
                                            <p key={index} className='neutral-80 mt-0'>{renderTextWithBold(item.content)}</p>
                                        </div>
                                        {
                                            item?.list && item.list.map(itemData => (
                                                <div className='flex align-start ml-xl'>
                                                    <span className='mr-s'>{itemData.number}</span>
                                                    <p key={index} className='neutral-80 mt-0'>{renderTextWithBold(itemData.content)}</p>
                                                </div>
                                            ))
                                        }
                                    </>
                                ))
                            }
                    </div>
                ))}
            </div>

            {/* lista enumerada 2*/}
            <div>
                {data.numeric_list_2.map((list, index) => (
                    <div key={index}>
                        <h2 className='blue-100'>{list.title}</h2>
                            {
                                list.list.map((item, index) => (
                                    <>
                                        <div className='flex align-start ml-l bold'>
                                            <span className='mr-s'>{item.label_number}</span>
                                            <p key={index} className='blue-100 mt-0 mb-0'>{renderTextWithBold(item.label)}</p>
                                        </div>
                                        {
                                            item.items.map(itemList => (
                                                <div className='flex align-start ml-l'>
                                                    <span className='mr-s'>{itemList.number}</span>
                                                    <p key={index} className='neutral-80 mt-0'>{renderTextWithBold(itemList.content)}</p>
                                                </div>
                                            ))
                                        }
                                    </>
                                ))
                            }
                    </div>
                ))}
            </div>

        </div>

        </div>
        <Footer />
    </>
  );
};

export default TermAndConditions;
