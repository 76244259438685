import React from 'react'
import './styles.css'

import Mobile from '../assets/section-1/sunmi.png'

import Metodos from '../assets/section-1/medios-de-pago.svg'
import PagosMixtos from '../assets/section-1/pagos-mixtos.svg'
import Tarjetas from '../assets/section-1/tarjetas.svg'

const SeccionOne = () => {

    const data = [
        {
            title: 'Varias opciones para cobrar',
            text: 'Registra los pagos recibidos por transferencias, tarjeta o efectivo en cualquier moneda.',
            img: Metodos
        },
        {
            title: 'Todas las tarjetas',
            text: 'Acepta tarjetas nacionales e internacionales con un dispositivo de punto de venta Sunmi',
            img: PagosMixtos
        },
        {
            title: 'Pagos mixtos',
            text: 'Acepta varios métodos de pago en una misma compra.',
            img: Tarjetas
        },
    ]

    const renderData = (data) => {
        return (
            <>
            <div className='seccion-one-subtitle'>
                <img src={data.img} style={{ width: 28 }} alt="asd" />
                <p className='heading-small blue-100'>{data.title}</p>
            </div>
            <p className='text-size-small neutral-80 section-one-text'>{data.text}</p>
            </>
        )
    }

    return(
        <div className='seccion-one-container'>
            <div className='seccion-one-leftbox'>
                <img src={Mobile} alt="mobile white" />
            </div>
            <div className='seccion-one-rightbox'>
                <h2 className='heading-medium blue-100' style={{ width: '90%' }}>Vende fácil en cualquier <br />método de pago</h2>
                {
                    data.map(info => {
                        return renderData(info)
                    })
                }
            </div>
        </div>
    )
}

export default SeccionOne