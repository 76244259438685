import React from 'react'
import './cta.css'

import Ellipse from '../assets/cta/ellipse.svg'
import Image from '../assets/cta/cta-image.svg'
import ImageRes from '../assets/cta/cta-image-res.svg'

import { config } from '../constants'

const CallToAction = () => {

    const whatsapp = () => {
        window.open(`https://wa.me/${config.wsNumber}`);
    }

    return(
        <div className='cta-container' id='inicio'>
            <div className='cta-left-box'>
                <div className='cta-info-container'>
                    <h1 className='heading-large blue-100'>Una app de POS adaptada a ti</h1>
                    <p className='text-size-large neutral-80'>Facilita las ventas de tu negocio desde el inicio.</p>
                    <div className='cta-buttons-container'>
                        <div className='btn-primary pointer' onClick={whatsapp} style={{ marginRight: 20}}>Contáctanos</div>
                        <a className='btn-secondary pointer' href='#yt' style={{ marginRight: 'auto' }}>Cómo empezar</a>
                    </div>
                </div>
            </div>
            <div className='cta-right-box'>
                <img className='cta-ellipse' src={Ellipse} alt="ellipse" />
                {
                    window.screen.width <= 770 ?
                    <img style={{ height: '100%' }} src={ImageRes} alt="tel" />
                    :
                    <img className='' src={Image} alt="tel" />
                }
            </div>
        </div>
    )
}

export default CallToAction