import React, { useState } from 'react'
import './header.css'
// libs 
import { Link, useNavigate, useLocation } from "react-router-dom";
// assets 
import LogoPosmit from '../assets/Logo-posmit-grande.svg'
import MenuIcon from '../assets/menu-icon.svg'
import CloseIcon from '../assets/close.svg'
import Arrow from '../assets/arrow.svg'

import { config } from '../constants';

const Header = () => {

    const [open, setOpen] = useState(false)
    let navigate = useNavigate();
    let location = useLocation();

    const handleClick = () => {
        setOpen(!open)
    }

    const goToHome = () => {
        navigate('/')
    }

    const whatsapp = () => {
        window.open(`https://wa.me/${config.wsNumber}`);
    }

        return(
            <header className='header-container'>
                <div className={open ? 'menu open-menu' : 'menu closed-menu'}>
                    <div className='flex'>
                        {
                            location.pathname === '/' ?
                            <a href="#inicio">
                                <img src={LogoPosmit} className="header-logo pointer" alt="logo posmit grande" />
                            </a>
                            :
                            <img src={LogoPosmit} className="header-logo pointer" alt="logo posmit grande" onClick={goToHome} />
                        }
                        <img src={CloseIcon} className="close-icon" alt="close menu" onClick={handleClick} />
                    </div>
                    <div className='divider'></div>
                    <div className='flex'>
                        <Link to="/" className='text-size-large ml-l bold dec-none nav-link' onClick={() => setOpen(false)}>Inicio</Link>
                        <img src={Arrow} className="close-icon" alt="" />
                    </div>
                    <div className='divider'></div>
                    {/* <div className='flex'>
                        <a className='text-size-large ml-l bold dec-none nav-link' href='#cta2' onClick={() => setOpen(false)}>Cómo empezar</a>
                        <img src={Arrow} className="close-icon" alt="" />
                    </div> */}
                    <div className='divider'></div>
                    <div className='flex'>
                        <Link to="/terms-and-conditions" className='text-size-large ml-l bold dec-none nav-link'>Términos y condiciones</Link>
                        <img src={Arrow} className="close-icon" alt="" />
                    </div>
                    <div className='flex'>
                        <Link to="/preguntas-frecuentes" className='text-size-large ml-l bold dec-none nav-link'>Preguntas frecuentes</Link>
                        <img src={Arrow} className="close-icon" alt="" />
                    </div>
                </div>
                {
                    location.pathname === '/' ?
                    <a href="#inicio">
                        <img src={LogoPosmit} className="header-logo pointer" alt="logo posmit grande" />
                    </a>
                    :
                    <img src={LogoPosmit} className="header-logo pointer" alt="logo posmit grande" onClick={goToHome} />
                }
                {
                    window.screen.width > 769 ?
                    <>
                        <div className='header-links-container'>
                            {/* <a className='text-size-small neutral-60 ml-l dec-none nav-link' href='#cta2' onClick={() => setOpen(false)}>Cómo empezar</a> */}
                            <Link to="/preguntas-frecuentes" className='text-size-small neutral-60 ml-l dec-none nav-link'>Preguntas frecuentes</Link>
                            {/* <Link to="/terms-and-conditions" className='text-size-small neutral-60 ml-l dec-none nav-link'>Términos y condiciones</Link> */}
                        </div>
                        <div className='header-buttons-container'>
                            <div className='btn-primary pointer mr-l' onClick={whatsapp}>Contáctanos</div>
                            <a className='btn-secondary pointer' href='#yt'>Cómo empezar</a>
                        </div>
                    </>
                    :
                    <div className="ml-auto mr-l pointer" onClick={handleClick}>
                        <img src={MenuIcon} alt="" />
                    </div>

                }
            </header>
        )
}

export default Header