import React from 'react'
import Image from '../assets/email-image.jpg'
import ImageLogo from '../assets/email-image.svg'
import { useNavigate } from 'react-router-dom'
import './styles.css'

export default function EmailSend() {

    const navigate = useNavigate()

  return (
    <div className='email-page-container'>
        <div className='flex ai-center w-90 h-90 email-data-container'>
            <img src={Image} alt="" className='h-100 email-banner' />
            <div className='w-100 flex ai-center jc-center'>
                <div className='email-info-container shadows'>
                    <img src={ImageLogo} alt="" className='email-logo' />
                    <p className='heading-medium blue-100 text-center'>¡Gracias por contactarnos!</p>
                    <p className='text-size-medium neutral-60 text-center'>Recibiras todos nuestros anuncios importantes en tu correo electrónico</p>
                    <p className='btn-primary pointer' onClick={() => navigate('/')} >Volver al sitio web</p>
                </div>
            </div>
        </div>
    </div>
  )
}
