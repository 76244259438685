import React from 'react';
import './styles.css';
import Footer from '../footer/Footer';
import Faq from 'react-faq-component';
import Header from '../header/Header';

const FAQs = () => {
  const data1 = {
    // title: "FAQ (How it works)",
    rows: [
      {
        title:
          '¿Cómo asigno y configuro los roles o permisos en posmit? Se pueden personalizar?',
        content:
          'En el menú de ajustes de Posmit, encontrarás la opción de “Roles”, dentro de ella podrás crear y editar los roles según la necesidad de tu negocio y asignar los permisos divididos por módulos/función. Ej: Vendedor puede crear órdenes pero para eliminarlas necesita la clave del supervisor.',
      },
      {
        title: '¿Qué reportes / estadísticas puedo obtener?',
        content:
          'En el Dashboard de Posmit puedes visualizar distintos indicadores de gestión para facilitar la visualización del comportamiento de tu negocio y ayudarte a tomar mejores decisiones como:\n-Productos más vendidos.\n-Monto de ticket promedio.\n-Ventas totales.\n-Número de clientes recibidos durante un tiempo definido.\n-Alertas de reposición de productos en el inventario.\n-Cantidad productos vendidos.\n-Cantidad de productos promedio por ticket.\n-Valor aproximado del inventario.\n-Top de categorías vendidas.',
      },
      {
        title: '¿Qué puedo vender en Posmit? ',
        content:
          'Lo que desees. Cualquier tipo de productos, consumibles y/o servicios. ej: luminarias, alimentos, servicio de toldos para playa.',
      },
      {
        title:
          'Qué diferencia existe los Consumibles de Productos y Servicios?',
        content:
          'Por lo general, un consumible es aquel que incluyes al momento o en un futuro dentro de tu producto o servicio, pero de igual forma tienes la posibilidad de venderlo por separado. Si deseas vender un producto como una pizza, este tendrá consumibles como queso, pepperoni, etc. Si es un servicio como un toldo y sillas en la playa, puedes incluir un consumible como un agua y un refresco de 2 litros dentro del mismo.',
      },
      {
        title: '¿Cómo comienzo en Posmit?',
        content:
          'Regístrate desde tu dispositivo ingresando los datos que solicita la app en el formulario. Si lo deseas, puedes dirigirte a la sección “Cómo Empezar” en nuestra página web para aprender más sobre el proceso.',
      },
      {
        title: '¿Cómo creo un producto?',
        content:
          'En la sección de inventario, podrás crear varios tipos de producto, solo debes llenar los datos correspondientes para identificarlo, asignarle costos, unidades de medida, y reglas de control en inventario. Recuerda que es muy importante crear tus categorías de producto antes de comenzar a crear productos individuales, de modo que puedas clasificarlos y filtrarlos más fácilmente.',
      },
      {
        title: '¿Cómo creo una categoría?',
        content:
          'Para crear o editar una categoría de producto, debes dirigirte a la sección de Ajustes y luego “Categorías”. Allí puedes crear una nueva categoría; solo debes asignarle un nombre y escribir una pequeña descripción opcional.',
      },
      {
        title: '¿Cómo puedo cambiar la tasa de cambio?',
        content:
          'Puedes actualizar tu tasa de cambio entre la moneda principal y la secundaria desde la pantalla principal de la aplicación. En caso de trabajar con monedas adicionales, deber dirigirte a la sección de Ajustes, luego Monedas, y seleccionar la moneda correspondiente para poder editar la tasa respecto a la moneda principal.',
      },
    ],
  };

  const data2 = {
    // title: "FAQ (How it works)",
    rows: [
      {
        title: '¿Cómo cargo mi inventario?',
        content:
          'Puedes realizar una carga masiva de inventario desde tu navegador web utilizando una hoja de cálculo con todos tus datos. Tambien, puedes ingresar los datos de tus productos de forma manual desde tu dispositivo movil.',
      },
      {
        title: '¿Cómo creo un cliente?',
        content:
          'Al colocar el documento de identidad de tu cliente en una orden, la aplicación te llevará a un formulario para completar el resto de sus datos y guardarlos automáticamente en tu directorio de clientes.',
      },
      {
        title: '¿Qué tipo de impuestos puedo crear y cómo los configuro?',
        content:
          'Todos los impuestos fiscales exigidos por ley como el IVA y el IGTF estarán precargados en la app, así como también la opción de Exento de IVA. Si desea crear un impuesto adicional (ej: Servicio 10%), puede ir a Ajustes → Impuestos → Presionar botón de crear (+), seguir los pasos y listo!',
      },
      {
        title: '¿Cómo agrego consumibles a mis productos?',
        content:
          'En el módulo de “Productos”, una vez creado el consumible deseado, dentro de cada servicio o producto, debes presionar el botón de “editar producto” y agregar el consumible deseado en la pestaña de “Costos”, presionando la opción “Agregar Consumibles”.',
      },
      {
        title:
          '¿Los extras que agregue a mis productos o servicios, se restan de mi inventario?',
        content:
          'Si! De ese modo puedes llevar control de cada extra individual vendido, así no forme parte de tu producto.',
      },
      {
        title:
          '¿Si quito consumibles de mi producto en una orden, se restan de mi inventario?',
        content:
          'No, los consumibles que se eliminen de un producto o servicio que esté en una orden, no se restan de tu inventario pero si disminuyen el costo total del producto pero el precio de venta permanece igual, tal como se registró.',
      },
      {
        title: '¿Cómo asigno mesas a mis órdenes?',
        content:
          'Accede a la sección “Mi Cuenta” pulsando la foto de perfil de tu usuario o negocio en la esquina superior derecha de la pantalla. Desde allí, puedes activar la funcionalidad de mesas. Una vez activa, en cada orden aparecerá un nuevo campo para elegir la mesa deseada.',
      },
      {
        title: '¿Qué puedo hacer con el módulo de Órdenes Rápidas?',
        content:
          'Puedes seleccionar hasta cinco de tus productos más vendidos, y crear una plantilla de orden con estos productos pre-cargados, eliminando la necesidad de buscarlos y seleccionarlos en el inventario cada vez que necesites realizar una nueva venta.',
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    rowTitleColor: '#053F66',
    rowTitleTextSize: '24px',
    rowContentColor: '#70808D',
    owContentTextSize: '20px',
    rowContentPaddingLeft: 5,
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };

  return (
    <>
      <Header />
      <div className="faqs-container">
        <p className="heading-large blue-100 m-0 text-center">
          Preguntas frecuentes
        </p>
        <p className="text-size-large neutral-80 text-center">
          Queremos ayudarte, ubica la respuesta que necesitas o escríbenos por
          correo electrónico.
        </p>
        <div className="faqs">
          <div className="faq-container">
            <Faq data={data1} styles={styles} config={config} />
          </div>
          <div className="faq-container">
            <Faq data={data2} styles={styles} config={config} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default FAQs;
