import React from 'react'
import './styles.css'

import Posmitsunmi from '../assets/section-2/posmitsunmi.svg'
import Image from '../assets/section-2/image.png'
import Card from '../assets/section-2/card.svg'
import Coins from '../assets/section-2/coins.svg'
import Phone from '../assets/section-2/phone.svg'
import Cash from '../assets/section-2/cash.svg'

const SectionTwo = () => {
    return(
        <div className='Section-two-container'>
            <div className='section-two-box section-two-first-box'>
                {
                    window.screen.width > 768 &&
                    <img src={Posmitsunmi} alt="posmit + sunmi" />
                }
                <p className='heading-small neutral-dark m-0-auto'>Posmit es compatible con SUNMI</p>
                <p className='text-size-large neutral-80 m-0-auto mt-m'>Recibe pagos con tarjeta y disfruta de las funciones adicionales de un dispositivo de punto de venta.</p>
            </div>
            <div className='section-two-box section-two-second-box'>
                <div className='section-two-box-icon'><img src={Card} alt="card" /></div>
                <div className='section-two-box-icon'><img src={Coins} alt="coins" /></div>
                <div className='section-two-box-icon'><img src={Phone} alt="Phone" /></div>
                <div className='section-two-box-icon'><img src={Cash} alt="Cash" /></div>
            </div>
            <div className='section-two-box section-two-three-box'>
                {window.screen.width <= 768 && <img src={Posmitsunmi} alt="posmit + sunmi" />}
                <img src={Image} alt="posmit + sunmi" />
            </div>
            {/* <div className='section-two-title-container'>
                <h2 className='heading-medium neutral-dark text-center w-m m-0-auto'>Desde la gestión tus ventas, hasta procesamiento de pagos</h2>
                <p className='text-size-large neutral-80 text-center w-m m-0-auto mt-m'>Integra Posmit con sus plataformas aliadas y centraliza todas tus operaciones financieras</p>
            </div>
            <div className='section-two-cards-container'>
                <div className='section-two-card first-card'>
                    <img className='section-two-card-image' src={Image1} alt="Image1" />
                    <h3 className='heading-xsmall blue-100'>Lleva las cuentas en tu Android o PC.</h3>
                    <p className='text-size-small neutral-80'>Monitorea tus ventas, cobros e inventario con la app de Posmit en tu dispositivo.</p>
                    <p className='text-size-small neutral-dark mt-l section-two-link'>Descarga la App</p>
                    <img src={Arrow} className="section-two-arrow" alt="arrow" />
                </div>
                <div className='section-two-card second-card'>
                    <img className='section-two-card-image' src={Image2} alt="Image1" />
                    <h3 className='heading-xsmall blue-100'>Tu ecosistema de pagos con Sunmi y Credicard</h3>
                    <p className='text-size-small neutral-80'>Procesa pagos con tarjeta a través de credicard y <span className='blue-40'>utiliza los dispositivos sunmi para disfrutar de la funcionalidad completa de un terminal de caja.</span></p>
                    <p className='text-size-small neutral-dark mt-l section-two-link'>Ver más</p>
                    <img src={Arrow} className="section-two-arrow" alt="arrow" />
                </div>
            </div> */}
        </div>
    )
}

export default SectionTwo