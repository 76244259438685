import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation } from "react-router-dom";
import './index.css';
import App from './App';
import FAQs from './faqs/FAQs';
import TermAndConditions from './term-and-conditions/TermAndConditions'
import PoliciesPersonalData from './term-and-conditions/PoliciesPersonalData';
import EmailSend from './email-send/EmailSend';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<App id="page-wrap" />} />
        <Route path="/preguntas-frecuentes" element={<FAQs  id="page-wrap" />} />
        <Route path="/terms-and-conditions" element={<TermAndConditions  id="page-wrap" />} />
        <Route path="/terms-and-conditions/policies-personal-data" element={<PoliciesPersonalData  id="page-wrap" />} />
        <Route path="/success-email" element={<EmailSend />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
