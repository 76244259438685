import './App.css';
import './index.css';
import Header from './header/Header'
import CTA from './callToAction/CallToAction'
import SeccionOne from './seccion-1/SeccionOne'
import SectionThree from './seccion-2/SectionTwo'
import SectionTwo from './section-3/SectionThree'
import SectionFour from './section-5/sectionFive'
import SectionFive from './all-that-you-need/AllThatYouNeed'
import SectionSix from './section-6/SectionSix'
import SectinoYt from './section-yt/SectinoYt'
import CTA2 from './callToAction2/CallToAction2'
import Footer from './footer/Footer'

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";


import { createTheme, ThemeProvider } from '@mui/material/styles';

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   projectId: process.env.REACT_APP_PROYECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKED,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

let theme = createTheme({
  palette: {
    primary: {
      main: '#0c88ed',
    },
    secondary: {
      main: '#053f66',
    },
  },
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <CTA />
        <SeccionOne />
        <div className='section-two-title-container' style={{ marginTop: 50 }}>
          <h2 className='heading-medium neutral-dark text-center w-m m-0-auto'>Ten el control en todo momento</h2>
          <p className='text-size-large neutral-80 text-center w-m m-0-auto mt-m'>Monitorea cada aspecto de tu negocio de forma sencilla, en un solo lugar.
</p>
        </div>
        <SectionTwo />
        <SectinoYt />
        <SectionThree />
        {/* <SectionFour /> */}
        <SectionFive />
        {/* <SectionSix /> */}
        <CTA2 />
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
