import React from 'react'
import './styles.css'

import Logo from '../assets/footer/logo-posmit.svg'
import Gplay from '../assets/gplay.svg'

import Whatsapp from '../assets/footer/whatsapp.svg'
import Instagram from '../assets/footer/instagram.svg'
import Facebook from '../assets/footer/facebook.svg'
import Linkedin from '../assets/footer/linkedin.svg'
import Mail from '../assets/footer/mail.svg'

import { config } from '../constants'
import { Link } from 'react-router-dom'

const Footer = () => {

    const whatsapp = () => {
        window.open(`https://wa.me/${config.wsNumber}`);
    }

    return(
        <footer className='footer'>
            <div className='footer-box1'>
                <img src={Logo} alt="logo" />
                <p className='text-size-small neutral-80'>Facilita las ventas de tu negocio desde el inicio.</p>
                <div className='btn-primary pointer' style={{ width: window.screen.width > 480 ? '166px' : '100%' }} onClick={whatsapp} >Contáctanos</div>
                <p className='heading-xsmall blue-100 title-footer'>Dirección</p>
                <p className='text-size-small neutral-80'>Oficentro, Av. Principal de Los Ruices, Caracas 1071, Miranda</p>
                <p className='text-size-small neutral-80'>Powered by 3mit <span className='blue-100'>www.3mit.dev</span></p>
                <Link to="/terms-and-conditions" className='text-size-small neutral-80'>Términos y condiciones</Link>

                {window.screen.width < 480 && <div className='divider' />}
            </div>
            <div className='footer-box2'>

                <div className='footer-box2-container-contact'>
                    {/* contacto  */}
                    {
                        window.screen.width > 480 ?
                        <div className='footer-contact'>
                            <p className='heading-xsmall blue-100'>Contacto</p>
                            {/* whatsapp  */}
                            <div className='footer-contact-info-container'>
                                <img src={Whatsapp} style={{ marginRight: 10 }} alt="whatsapp" />
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 m-0 bold'>WhatsApp </p>
                                    <a href={`https://wa.me/${config.wsNumber}`}  className='text-size-small neutral-80 m-0 nav-link-footer'>+58 412-535-4531</a>
                                </div>
                            </div>
                            {/* correo  */}
                            <div className='footer-contact-info-container'>
                                <img src={Mail} style={{ marginRight: 10 }} alt="whatsapp" />
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>Correo</p>
                                    <p className='text-size-small neutral-80 m-0 nav-link-footer'>info@posmit.app</p>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <p className='heading-xsmall blue-100 title-footer'>Contacto</p>
                            <div className='footer-contact-info-container'>
                                <img src={Whatsapp} style={{ marginRight: 10 }} alt="whatsapp" />
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>WhatsApp </p>
                                    <a href={`https://wa.me/${config.wsNumber}`} className='text-size-small neutral-80 m-0'>+58 412-535-4531</a>
                                </div>
                            </div>
                            <div className='footer-contact-info-container'>
                                <img src={Mail} style={{ marginRight: 10 }} alt="whatsapp" />
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>Correo</p>
                                    <p className='text-size-small neutral-80 m-0'>info@posmit.app</p>
                                </div>
                            </div>
                            {window.screen.width < 480 && <div className='divider' />}
                        </div>
                    }
                    {/* siguenos  */}
                    {
                        window.screen.width > 480 &&
                        <div className='footer-contact'>
                            <p className='heading-xsmall blue-100'>Síguenos</p>
                            {/* instagram  */}
                            <div className='footer-contact-info-container'>
                                <img src={Instagram} style={{ marginRight: 10 }} alt="whatsapp" />
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>Instagram</p>
                                    <a className='text-size-small neutral-80 m-0 nav-link-footer' target='_blank' rel='noreferrer' href='https://instagram.com/posmit.app?igshid=YmMyMTA2M2Y='>@posmit.app</a>
                                </div>
                            </div>
                            <div className='footer-contact-info-container'>
                                <img src={Facebook} style={{ marginRight: 10 }} alt="whatsapp" />
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>Facebook</p>
                                    <a className='text-size-small neutral-80 m-0 nav-link-footer' target='_blank' rel='noreferrer' href='https://www.facebook.com/Posmitapp-105123388974810'>facebook.com/posmit.app</a>
                                </div>
                            </div>
                            <div className='footer-contact-info-container'>
                                <img src={Linkedin} style={{ marginRight: 10 }} alt="whatsapp" />
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>LinkedIn</p>
                                    <a className='text-size-small neutral-80 m-0 nav-link-footer' target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/posmit-app/'>linkedin.com/company/posmit</a>
                                </div>
                            </div>
                        </div>
                    }
                    {/* allies  */}
                    {
                        window.screen.width > 480 ?
                        <div className='footer-contact'>
                            <p className='heading-xsmall blue-100'>Aliados</p>
                            {/* rif  */}
                            <div className='footer-contact-info-container'>
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>Sunmi Venezuela</p>
                                    <p className='text-size-small neutral-80 m-0 nav-link-footer'>sunmivzla.com</p>
                                </div>
                            </div>
                            <div className='footer-contact-info-container'>
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>Bansistemas</p>
                                    <p className='text-size-small neutral-80 m-0 nav-link-footer'>bansistemas.com</p>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <p className='heading-xsmall blue-100 title-footer'>Aliados</p>
                            <div className='footer-contact-info-container'>
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>Sunmi Venezuela</p>
                                    <p className='text-size-small neutral-80 m-0'>sunmivzla.com</p>
                                </div>
                            </div>
                            <div className='footer-contact-info-container'>
                                <div className='footer-contact-info'>
                                    <p className='text-size-small blue-100 bold m-0'>Bansistemas</p>
                                    <p className='text-size-small neutral-80 m-0'>bansistemas.com</p>
                                </div>
                            </div>
                            {window.screen.width < 480 && <div className='divider' />}
                        </div>
                    }
                    {
                        window.screen.width < 480 &&
                        <div className='flex jc-center'>
                            <img src={Instagram} style={{ margin: 30 }} alt="whatsapp" />
                            <img src={Facebook} style={{ margin: 30 }} alt="Facebook" />
                            <img src={Linkedin} style={{ margin: 30 }} alt="Linkedin" />
                        </div>
                    }
                </div>

            </div>
        </footer>
    )
}

export default Footer